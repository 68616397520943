import React, { useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";

const ContactURL = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;


  useEffect(() => {
    if (path === "/contact") {
      navigate('/', { state: { scroll:true} });
    }
  }, [path]);

};
export default ContactURL;
