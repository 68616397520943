import React from "react";

function PartnersNetwork() {
 

  return (
    <div className="my-[150px] flex flex-col items-center">
      <h1 className="md:text-5xl text-2xl font-extrabold text-center text-gray-900">
        The AWS Partner you can trust
      </h1>

      <p className="text-[#5D5D5D] self-center mx-[50px] max-w-[800px] 2xl:max-w-[1000px] text-center  mt-5 md:mt-[15px] text-md">
        As an AWS Consulting Partner, Nautilus helps enterprises and startups to architect, build, optimize, secure, and manage their cloud.
        We offer a range of AWS cloud consulting services and capabilities to bring your cloud aspirations to fruition.
        Our experts will support you in technical assessment, infrastructure setup, architecture design, migration to AWS, cloud optimization, configuration tuning, and more.
      </p>
      {/* <a href="/rds" className="w-full xl:w-[50%]">
        <div className="mt-20 w-full bg-white border-[1px] rounded-xl py-5
          border-[#3B82F6 bg-[#DCE8FF] text-center
          font-bold text-xl"
        >
          AWS Database Services
        </div>
      </a> */}
    </div>
  );
}

export default PartnersNetwork;
