import React from "react";
import awscard from "../../assets/images/aws-Partner.png";
import AWSLogo from "../../assets/images/aws/AWS-logo.png";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { XPadding } from "./Aws";
import "./aws.css";

function HeroSection() {
  const navigate = useNavigate();
  const [move] = React.useState(true);

  return (
    <section className={`section
      w-full h-[550px bg-[#3B82F6] flex flex-col lg:flex-row-reverse
      items-center lg:justify-between text-white py-20 md:py-[160px] ${XPadding}`
    }>
      <div className="self-start lg:w-[30%] flex flex-row items-center justify-start lg:justify-end pb-3 md:pb-5">
        <img src={AWSLogo} alt="AWS logo" width={200} height={200} className="w-[150px] self-start lg:self-end my-5
          lg:w-[200px] xl:w-[250px] 2xl:w-[300px]"
        />
      </div>
      <div className="flex flex-col lg:w-[70%] items-between">
        <h1 className="border-b border-b-[1px] border-opacity-50
          pb-3 md:pb-6 text-[25px] sm:text-4xl md:text-[50px] xl:text-[70px] font-extrabold nowrap w-max"
        >
          AWS Consulting Partner
        </h1>
        <h3 className="py-2 text-[18px] sm:text-[30px] lg:text-[40px] font-bold nowrap sm:leading-[35px] lg:leading-[45px]">
          Design, build, migrate, and operate complex AWS environments at scale.
        </h3>
        <p className="text-md md:text-lg">
          Nautilus Technologies helps startups speed up delivery and decrease operational overhead through cloud technology.
          We offer expert AWS cloud solution services to help your business take advantage of the cloud.
        </p>
      </div>
    </section>
  )

  /* return (
    <div
      className="w-full h-[400px] bg-slate-600 cursor-pointer"
      onClick={() => navigate("/amazon-web-services")}
    >
      <div className="flex justify-center pt-24 gap-[200px]">
        <div className="hidden md:block flex flex-col ">
          <h1 className="text-4xl text-white font-bold">AWS Cloud Solutions</h1>
          <h6 className="text-lg text-black font-bold mt-4">
          SECURE YOUR DATA IN THE CLOUD
          </h6>
          <p className="text-md text-[#E5EFFF] w-[600px]">
          As one of the AWS Premier Consulting Partners, Nautilus Technologies can help you create seamless storage across your cloud and on-premises systems to enable business continuity. We utilize cloud storage products and solutions, such as backup, cloud NAS, archiving disaster recovery, and file transfer, pre-qualified by the AWS Partner Competency Program.
          </p>
        </div>
        <motion.div
          animate={{ y: move ? 12 : -12 }}
          transition={{ delay: 1 }}
          className="hidden md:block"
        >
          <img src={awscard} alt="aws premeir card" className="w-[400px]" />
        </motion.div>
      </div>
      <div className="block md:hidden lg:hidden flex flex-col mt-[-50px]">
        <h1 className="text-2xl text-white text-center font-bold">
          AWS Cloud Solutions
        </h1>
 
          <h6 className="text-lg text-black text-center font-bold">SECURE YOUR DATA</h6>
   
        <p className="text-md text-[#E5EFFF] text-center px-4 ">
        As one of the AWS Premier Consulting Partners, Nautilus Technologies can help you create seamless storage across your cloud and on-premises systems to enable business continuity. We utilize cloud storage products and solutions, such as backup, cloud NAS, archiving disaster recovery, and file transfer, pre-qualified by the AWS Partner Competency Program.
        </p>
      </div>
    </div>
  ); */
}

export default HeroSection;
