import cloud from "../assets/images/cloud.svg";
import devops from "../assets/images/devops.svg";
import SRE from "../assets/images/SRE.svg";
import cyberSecurity from "../assets/images/cyberSecurity.svg";
import training from "../assets/images/training.svg";

export const cards = [
    
    {
      title: "DevOps",
      subtitle:
        "We provide a range of DevOps services, including continuous integration, delivery, deployment, monitoring, and security. We also offer release engineering and automated, zero downtime deployments.",
      image: devops,
      link: '',
    },
    {
      title: "RDS",
      subtitle:
        "Providing relational database solutions for speed and maximized efficeincy.",
      image: cyberSecurity,
      link: '/rds',
    },
    {
      title: "SRE and Infrastructure as a service",
      subtitle:
        "We can help you set up infrastructure to achieve a high level of uptime and reliability, as well as automate repetitive processes to improve efficiency.",
      image: SRE,
      link: '',
    },
    {
      title: "Cloud Migration",
      subtitle:
        "We can help you migrate to the Cloud and optimize your infrastructure for cost efficiency and performance. Our services include cloud strategy and design, hybrid cloud setup, data migration, and more.",
      image: cloud,
      link: '',
    },
    {
      title: "Disaster Recovery and Business Continuity",
      subtitle: "Helping clients plan and implement strategies for disaster recovery and business continuity in the Cloud.",
      image: cloud,
      link: '',
    },
    {
      title: "Cyber Security Consultancy",
      subtitle:
        "We offer a range of cyber security services, including compliance setup, DevSecOps, application and cloud security, and best practice implementation.",
      image: cyberSecurity,
      link: '',
    },
    {
      title: "Cloud Compliance and Governance",
      subtitle:
        "Providing guidance and support for complying with regulatory requirements and industry standards in the Cloud.",
      image: training,
      link: '',
    },
    {
      title: "Cloud Cost Optimization",
      subtitle:
        "Assisting clients in optimizing their Cloud spending and identifying cost-saving opportunities.",
      image: SRE,
      link: '',
    },
    {
      title: "Training and Support",
      subtitle:
        "We believe in continuous learning and improvement, and offer training and coaching in various technologies and methodologies to help our clients stay up-to-date and achieve their goals.",
      image: training,
      link: '',
    },
  
  ];