import React from "react";
import HappyMan from "../../assets/images/aws/happyman.png";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContactScrollContext } from "../../contexts/GlobalContext";
import { XPadding } from "./Aws";
import "./aws.css";

const GetStarted =() => {
  const [contactScroll, setContactScroll] = useContext(ContactScrollContext);
  const navigate = useNavigate();

  const contactUs = () => {
    setContactScroll(true);
    navigate('/');
  };

  return (
    <section className={`section w-full bg-[#DCE8FF] mb-[-40px] pt-10 ${XPadding}
      flex flex-col sm:flex-row items-center justify-between
      `
    }>
      <div>
        <h1 className="text-3xl font-extrabold pb-3">
          READY TO REDEFINE WHAT’S POSSIBLE?
        </h1>
        <p className="pr-3 max-w-[500px] mb-7">
          Choose the right AWS Cloud solution services for better quality and efficiency.
          Our expert AWS consulting team will work closely with your team to come up with a strategy for quick deployment and a stable release.
          Leave your worries to us; we've got the best & cost effective solution for you. Let's talk about your project today.
        </p>
        <button className="bg-[#0060FF] text-white rounded-xl py-3 px-5 mb-5" onClick={() => contactUs()}>
          Let's Talk
        </button>
      </div>
      <img src={HappyMan} alt='happy picture' className="w-[150px] sm:w-[200px] md:w-[280px] xl:w-[320px] self-end" />
    </section>
  )
}

export default GetStarted;
