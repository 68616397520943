import { useLocation } from "react-router-dom";

const SideBrief = () => {
  const location = useLocation();
  const path = location.pathname;
  if (path === "/sidebrief") {
    return window.location.replace('https://forms.gle/hsVpTccxNvMnB5Cu6');
  }
};

export default SideBrief;
