import { XPadding } from "./Rds";
import Server from "../../../assets/images/aws/server1.png";
import Chain from "../../../assets/images/aws/chain.png";
import Stock from "../../../assets/images/aws/stock.png";
import { useState } from "react";

const more = [
  {
    icon: Server,
    title: 'Leverage Managed Databases',
    description: 'Automate provisioning, managing, and scaling databases, allowing you to focus on higher-value app development work with purpose-built databases.',
  },
  {
    icon: Chain,
    title: 'Regain Database Freedom',
    description: 'Replace your inflexible commercial databases with cloud-optimized, open source-compatible databases that provide better performance at a lower cost.',
  },
  {
    icon: Stock,
    title: 'Increase Database Scalability',
    description: 'Take advantage of near-unlimited database scalability and high availability in the cloud, ensuring your database is available for your apps at all times.',
  }
]

const More = () => {
  const activeColor = {bg: 'bg-[#3B82F6]', iconBg: 'bg-[#4C90FF]'};
  const resetHighlight = [false, false, false];
  const [highlightState, setHighlightState] = useState([true, false, false]);

  return (
    <section className={`${XPadding} bg-[#0F1527] flex flex-col items-center py-[120px] md:py-[150px]
      rounded-[30px] md:rounded-[50px] mt-20`
    }>
      <h2 className="text-white text-center text-[25px] md:text-[30px] xl:text-[35px] 2xl:text-[45px] font-bold md:leading-[35px] 2xl:leading-[45px]">
        Achieve More With Nautilus and AWS
      </h2>
      <div className="w-full mt-20 flex flex-col lg:flex-row items-center justify-center gap-5">
        {
          more.map((item, index) => {
            return (
              <div key={index} className={`
                ${highlightState[index] ? activeColor.bg : ''}
                h-[400px] w-[300px] lg:h-[450px] lg:w-[380px] px-8 md:px-10
                border-[5px] border-[#252A41] rounded-[50px] text-white
                flex flex-col items-center pt-14 gap-5 cursor-pointer`}
                onClick={() => {
                  const newState = [...resetHighlight];
                  newState[index] = true;
                  setHighlightState(newState);
                }}
              >
                <div className={`
                  ${highlightState[index] ? activeColor.iconBg : 'bg-[#192138]'}
                  h-[95px] w-[95px] rounded-[20px]
                  flex items-center justify-center`
                }>
                  <img src={item.icon} alt='icon' width={50} />
                </div>
                <h3 className="text-2xl font-bold text-center">
                  {item.title}
                </h3>
                <p className={`text-center text-sm ${highlightState[index] ? '' : 'text-[#898FA3]'}`}>
                  {item.description}
                </p>
              </div>
            )
          })
        }
      </div>
    </section>
  )
}

export default More;
