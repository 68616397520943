import React from "react";
import Layout from "../components/shared/Layout";
import aboutImage from "../assets/images/about.png";
import { motion } from "framer-motion";
import { MdBolt, MdAdd, MdRemove } from "react-icons/md";
import {
  HiOutlineSparkles,
  HiOutlineLightBulb,
  HiOutlineBadgeCheck,
} from "react-icons/hi";
import Update from "../components/Update";

function MissionCard() {
  const [overlay, setOverlay] = React.useState(false);
  return (
    <motion.div className=" w-full md:w-[48%] xl:w-[30%] border border-gray-200 bg-white hover:border-none hover:shadow-2xl hover:shadow-[#40a7f03b] group p-6 space-y-6 relative overflow-hidden">
      <div
        className="flex justify-between space-x-10"
        onClick={() => {
          setOverlay(true);
        }}
      >
        <div className="flex space-x-3 rounded-full items-center px-2 py-1 bg-[#F8F8F8] group-hover:bg-[#F1F9FE] group-hover:text-[#40A6F0]">
          <MdBolt />
          <p className="font-medium">How we do it</p>
        </div>
        <MdAdd
          className="p-3 rounded-full box-content
              bg-[#F8F8F8] group-hover:bg-[#40A6F0]
              group-hover:text-white hover:cursor-pointer
              "
          onClick={() => {
            setOverlay(true);
          }}
        />
      </div>
      <p className="text-5xl font-extrabold ">
        Our Mission <br />
      </p>

      <HiOutlineLightBulb className="text-[320px] font-extralight relative -right-6 text-[#F1F9FE] group-hover:text-[#40A6F0]" />
      {overlay && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "0" }}
          exit={{ y: "100%" }}
          className="absolute -top-6 bg-[#40A6F0] left-0 w-full h-full flex flex-col p-6 space-y-4"
        >
          <MdRemove
            className="p-3 rounded-full box-content
              bg-[#F8F8F8]  hover:cursor-pointer self-end
              "
            onClick={() => {
              setOverlay(false);
            }}
          />
          <p className="pt-10 text-4xl font-bold text-white">Our Mission</p>
          <p className="text-lg font-medium leading-[180%] text-white">
            Our mission at Nautilus Technologies is to help startups succeed
            through expert DevOps and Cloud Solutions services. We provide
            high-quality, reliable, and user-friendly solutions that enable our
            clients to build, maintain, and optimize their Cloud Infrastructure
            at scale. We strive to be a trusted partner for startups, delivering
            exceptional service and support as they grow and achieve their business goals.
            <br />
          </p>
        </motion.div>
      )}
    </motion.div>
  );
}

function VisionCard() {
  const [overlay, setOverlay] = React.useState(false);
  return (
    <motion.div className=" w-full md:w-[48%] xl:w-[30%] border border-gray-200 bg-white hover:border-none hover:shadow-2xl hover:shadow-[#40a7f03b] group p-6 space-y-6 relative overflow-hidden">
      <div
        className="flex justify-between space-x-10"
        onClick={() => {
          setOverlay(true);
        }}
      >
        <div className="flex space-x-3 rounded-full items-center px-2 py-1 bg-[#F8F8F8] group-hover:bg-[#F1F9FE] group-hover:text-[#40A6F0]">
          <MdBolt />
          <p className="font-medium">Why we do it</p>
        </div>
        <MdAdd
          className="p-3 rounded-full box-content
              bg-[#F8F8F8] group-hover:bg-[#40A6F0]
              group-hover:text-white hover:cursor-pointer
              "
        />
      </div>
      <p className="text-5xl font-extrabold ">
        Our Vision <br />
      </p>

      <HiOutlineSparkles className="text-[320px] font-extralight relative -right-6 text-[#F1F9FE] group-hover:text-[#40A6F0]" />
      {overlay && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "0" }}
          exit={{ y: "100%" }}
          className="absolute -top-6 bg-[#40A6F0] left-0 w-full h-full flex flex-col p-6 space-y-4"
        >
          <MdRemove
            className="p-3 rounded-full box-content
              bg-[#F8F8F8]  hover:cursor-pointer self-end
              "
            onClick={() => {
              setOverlay(false);
            }}
          />
          <p className="pt-10 text-4xl font-bold text-white">Our Vision</p>
          <p className="text-lg font-medium leading-[180%] text-white overflow-scroll">
            Our vision at Nautilus Technologies is to be the leading Cloud
            Service Provider in Africa, empowering startups to build and
            architect their infrastructure to scale. We strive to be the
            trusted partner of choice for startups in Africa, helping them
            achieve their business goals and make a difference in their industries.
            With a focus on continuous innovation and improvement, we aim to be
            the go-to resource for startups looking to succeed in the tech industry.
          </p>
        </motion.div>
      )}
    </motion.div>
  );
}

function CoreValues() {
  const [overlay, setOverlay] = React.useState(false);
  return (
    <motion.div className=" w-full md:w-[48%] xl:w-[30%] border border-gray-200 bg-white hover:border-none hover:shadow-2xl hover:shadow-[#40a7f03b] group p-6 space-y-6 relative overflow-hidden">
      <div
        className="flex justify-between space-x-10"
        onClick={() => {
          setOverlay(true);
        }}
      >
        <div className="flex space-x-3 rounded-full items-center px-2 py-1 bg-[#F8F8F8] group-hover:bg-[#F1F9FE] group-hover:text-[#40A6F0]">
          <MdBolt />
          <p className="font-medium">Who we are</p>
        </div>
        <MdAdd
          className="p-3 rounded-full box-content
              bg-[#F8F8F8] group-hover:bg-[#40A6F0]
              group-hover:text-white hover:cursor-pointer
              "
          onClick={() => {
            setOverlay(true);
          }}
        />
      </div>
      <p className="text-5xl font-extrabold ">
        Core Values <br />
      </p>

      <HiOutlineBadgeCheck className="text-[320px] font-extralight relative text-[#F1F9FE] group-hover:text-[#40A6F0]" />
      {overlay && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "0" }}
          exit={{ y: "100%" }}
          className="absolute -top-6 bg-[#40A6F0] left-0 w-full h-full flex flex-col p-6 space-y-4"
        >
          <MdRemove
            className="p-3 rounded-full box-content
              bg-[#F8F8F8]  hover:cursor-pointer self-end
              "
            onClick={() => {
              setOverlay(false);
            }}
          />
          <p className="pt-10 text-4xl font-bold text-white">Core Values</p>
          <p className="text-lg font-medium overflow-scroll text-white">
            <li className="pb-3">
              <span className="font-medium">People</span> - We believe that people are the heart and soul of our business, and we value diversity, inclusivity, and respect for all individuals.
            </li>
            <li className="pb-3">
              <span className="font-medium">Commitment to Client</span> - Our clients are at the center of everything we do, and we are dedicated to meeting their needs and exceeding their expectations.
            </li>
            <li className="pb-3">
              <span className="font-medium">Excellence</span> - We are committed to delivering the highest quality services and solutions to our clients, and we strive for continuous improvement and excellence in everything we do.
            </li>
            <li className="pb-3">
              <span className="font-medium">Integrity</span> - We believe in honesty, transparency, and ethical behavior in all of our business practices.
            </li>
            <li className="pb-3">
              <span className="font-medium">Founder Mentality</span> - We embrace an entrepreneurial mindset and are passionate about helping startups achieve success.
            </li>
            {/* <li>Teamwork</li> */}
          </p>
        </motion.div>
      )}
    </motion.div>
  );
}

const About = () => {
  return (
    <>
      <Layout>
        <div className="flex flex-col space-y-40">
          <div className="flex flex-col lg:flex-row justify-center gap-x-20">
            <div
              className="w-full lg:w-[40%] space-y-6 p-10"
              data-aos="fade-up"
            >
              <p className="text-5xl font-extrabold leading-[105%] text-gray-900">
                About Nautilus
              </p>
              <p className="text-lg font-medium leading-loose text-gray-500">
                At Nautilus Technologies, we specialize in empowering startups
                to achieve success through expert DevOps and Cloud Solutions,
                with a focus on Site Reliability Engineering (SRE). From building
                and maintaining Cloud Infrastructure at Scale to ensuring reliable
                and high availability for product delivery, we provide startups
                with everything they need to thrive. Let us help you take your
                business to the next level with our SRE expertise.
                <br />
              </p>
            </div>
            <div
              className="w-full lg:w-[40%] min-h-[400px]"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="ease-in-back"
            >
              <img src={aboutImage} alt="" />
            </div>
          </div>

          <div className="flex flex-col flex-wrap md:flex-row md:gap-[24px] xl:gap-[48px]  ">
            <VisionCard />
            <MissionCard />
            <CoreValues />
          </div>
        </div>
      </Layout>
      <Update />
    </>
  );
};

export default About;
