import { useNavigate,useLocation } from "react-router-dom";

const Consultation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  if (path === "/devopsconsultation") {
    return window.location.replace('https://forms.gle/sYAQnUABvyczk1tW7');
  }
}

export default Consultation;
