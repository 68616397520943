import './App.css';
import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from './components/Navbar';
import { Home } from './views/Home';
import CaseStudies from './views/CaseStudies';
import Products from './views/Products';
import Footer from './components/Footer';
import About from './views/About';
import Services from './views/Services';
import Aws from './components/microsite/Aws';
import RDS from './components/microsite/Rds/Rds';
import Onboard from './components/external/Onboard';
import GetEquity from './components/external/GetEquity';
import Consultation from './components/external/Consultation';
import { useState } from 'react';
import { ContactScrollContext } from './contexts/GlobalContext';
import SideBrief from './components/external/SideBrief';
import ContactURL from './components/external/ContactURL';



function App() {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [contactScroll, setContactScroll] = useState(false);
  const location = useLocation();
  const externalLinks = [
    "/onboard",
    "/getequity",
    "/sidebrief",
    "/devopsconsultation",
  ];

  return (
    <>
      <ContactScrollContext.Provider value={[contactScroll, setContactScroll]}>
        { externalLinks.includes(location.pathname) ? <>Redirecting...</> : <Navbar /> }
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/case-studies" element={<CaseStudies />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/amazon-web-services" element={<Aws />} />
          <Route exact path="/rds" element={<RDS />} />
          <Route exact path="/contact" element={<ContactURL />} />
          <Route exact path="/onboard" element={<Onboard />} />
          <Route exact path="/getequity" element={<GetEquity />} />
          <Route exact path="/sidebrief" element={<SideBrief />} />
          <Route exact path="/devopsconsultation" element={<Consultation />} />
        </Routes>
      </ContactScrollContext.Provider>
      { externalLinks.includes(location.pathname) ? <></> : <Footer /> }
    </>
  );
}

export default App;
