import FAQ from "./FAQ";
import HeroSection from "./HeroSection";
import MainContent from "./MainContent";
import More from "./More";
import NextStep from "./Nextstep";

export const  XPadding = 'px-10 sm:px-[70px] lg:px-[60px] xl:px-[150px] 2xl:px-[150px]';

const RDS = () => {
  return (
    <div>
      <HeroSection />
      <MainContent />
      <More />
      <FAQ />
      <NextStep />
    </div>
  )
}

export default RDS;
