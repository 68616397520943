import React from "react";
import Layout from "../shared/Layout";
import cloud from "../../assets/images/aws/cloudIcon.png";
import devops from "../../assets/images/aws/devopsIcon.png";
import SRE from "../../assets/images/aws/sreIcon.png";
import OrangeFrame from "../../assets/images/aws/orangeFrame.png";
import BlueFrame from "../../assets/images/aws/blueFrame.png";
import GreenFrame from "../../assets/images/aws/greenFrame.png";
import { XPadding } from "./Aws";
import "./aws.css";
import { useNavigate } from "react-router-dom";


export const solutions = [
  {
    name: "Devops",
    icon: devops,
    href: '',
    frame: GreenFrame,
    bg: 'bg-[#E6F6EB]',
    information:
      "Continuous Everything (Integration, Delivery or Deployment, Monitoring and Security), Release Engineering, Automated & Zero Downtime Deployments",
  },
  {
    name: "Cloud Migration",
    icon: cloud,
    href: '',
    frame: BlueFrame,
    bg: 'bg-[#E9EFFB]',
    information:
      "Cloud Infrastructure Strategy and Design, Hybrid Cloud Setup, Data Migration, Cost Efficiency & Optimization",
  },
  {
    name: "SRE & Infrastructure as a service",
    icon: SRE,
    href: '',
    frame: OrangeFrame,
    bg: 'bg-[#FEF7E4]',
    information: "Setting up Infrastructure to achieve a 99.99% SLA across all verticals, Automating repetitive processes to reduce",
  },
  {
    name: "RDS",
    icon: cloud,
    href: '/rds',
    frame: BlueFrame,
    bg: 'bg-[#E9EFFB]',
    information:
      "Database design and relational database solutions for speed and maximized efficeincy.",
  },
  {
    name: "SRE & Infrastructure as a service",
    icon: SRE,
    href: '',
    frame: OrangeFrame,
    bg: 'bg-[#FEF7E4]',
    information: "Setting up Infrastructure to achieve a 99.99% SLA across all verticals, Automating repetitive processes to reduce",
  },
  {
    name: "Devops",
    icon: devops,
    href: '',
    frame: GreenFrame,
    bg: 'bg-[#E6F6EB]',
    information:
      "Continuous Everything (Integration, Delivery or Deployment, Monitoring and Security), Release Engineering, Automated & Zero Downtime Deployments",
  },
];

function SpecializedSolution() {
  const navigate = useNavigate();

  return (
    <div className={`section w-full h-auto flex flex-col justify-center mt-20 ${XPadding}`}>
      <h1 className="md:text-5xl text-2xl font-extrabold text-center text-gray-900 mt-10 md:mt-20">
        Services & Solution Offerings
      </h1>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 h-auto self-center items-center justify-between gap-5 mt-10 py-10">
        {solutions.map((query, index) => {
          return (
            <div key={index}
              className={`group ${query.href ? 'cursor-pointer' : ''} transition delay-100 hover:scale-105`}
              onClick={() => {
                query.href && (navigate('/rds'));
              }}
            >
              <div>
                <div className="relative w-full h-full rounded md:p-1 self-center">
                  <img
                    src={query.frame}
                    alt={query.name}
                    className=""
                  />
                  <div className="w-full h-full
                    absolute top-0 group-hover:hidden group-hover:transition-opacity
                    duration-1000 ease-in-out animate-fade hover:opacity-0
                    flex flex-col items-center justify-center"
                  >
                    <img
                      src={query.icon}
                      alt={query.name}
                      className="w-[25%] xl:w-[100px]"
                    />
                    <h2 className={`text-2xl font-bold md:leading-7 mt-[16px] text-center px-5 `}>
                      {query.name}
                    </h2>
                  </div>
                  <div className="h-full w-full
                    absolute hidden top-0 text-center p-5
                    group-hover:block group-hover:transition-opacity delay-200 duration-100"
                  >
                    <div className="h-full w-full flex flex-col items-center justify-center">
                      <h2 className={`text-2xl font-bold md:leading-7`}>
                        {query.name}
                      </h2>
                      <p className="text-sm px-2 pt-7">{query.information}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SpecializedSolution;
