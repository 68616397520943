import React,{useState} from "react";
import Layout from "./shared/Layout";
import emailjs from "@emailjs/browser";
import { Toaster } from 'react-hot-toast';
import { useFormik } from "formik";
import * as Yup from "yup";
import { alertError, alertSuccess } from "../utils";


const Contacts = () => {
  const [name,setName] = useState('')
  const [email,setEmail] = useState('')
  const [information,setInformation] = useState('');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      information: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Fullname is required."),
      email: Yup.string().required("Please provide a valid email address."),
      information: Yup.string().required("Please enter a messeage.")
        .min(5, 'Message must be a minimum of 10 characters.')
        .max(100, "Message must be at most 100 characters."),
    }),
    onSubmit: (values) => {
      setLoading(true)
      emailjs
      .send(
        "service_j3bjvq3",
        "template_dst5v57",
        values,
        "EGhWjYrm3lK8Rcb5s"
      )
      .then(
        (result) => {
          console.log(result.text);
          alertSuccess('Message sent! We would respond to you shortly.');
          setLoading(false);
          formik.resetForm();
        },
        (error) => {
          alertError('Error. Please check your email and retry');
          console.log(error.text);
        }
      );
    }
  });

  /* const submitForm = (e) => {
    e.preventDefault();
    const formBody = { fullName: name, email };
    
    emailjs
      .sendForm(
        "service_508619j",
        "template_najcyu5",
        e.target,
        "wwX744bFmCPnEBhVe"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      setName("");
      setEmail("");
      setInformation("");
  
  }; */
  return (
    <Layout>
      <div className="flex flex-col lg:flex-row justify-center gap-10 pt-10" id="contact">
        <div className="flex flex-col lg:w-[35%] space-y-6 md:text-center lg:text-left">
          <p className="text-base font-normal text-blue-500 " >
            We’ll like to know more
          </p>
          <p className=" text-5xl font-semibold">
            Tell us about your project
          </p>
        </div>
        <form onSubmit={formik.handleSubmit} className="shadow-2xl lg:w-[50%] flex flex-wrap p-6 lg:p-10 space-y-6 lg:space-y-10">
          <div className="flex flex-col w-full space-y-2">
            <label
              htmlFor="name"
              className="font-medium tracking-widest uppercase font-roboto text-sm"
            >
              Full name
            </label>
            <input
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Enter your full name"
              name="name"
              id="name"
              className="border border-gray-200 px-8 py-4"
            />
            {
              formik.touched.name && formik.errors.name
              ?
              <span className="text-[red] text-sm md:text-md ml-3">
                {formik.errors.name}
              </span>
              :
              <></>
            }
          </div>
          <div className="flex flex-col w-full space-y-2">
            <label
              htmlFor="email"
              className="font-medium tracking-widest uppercase font-roboto text-sm"
            >
              EMAIL ADDRESS
            </label>
            <input
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              placeholder="your@email.com"
              name="email"
              id="email"
              className="border border-gray-200 px-8 py-4"
            />
            {
              formik.touched.email && formik.errors.email
              ?
              <span className="text-[red] text-sm md:text-md ml-3">
                {formik.errors.email}
              </span>
              :
              <></>
            }
          </div>
          {/* <div className="flex flex-col w-full space-y-2">
            <label
              htmlFor="full_name"
              className="font-medium tracking-widest uppercase font-roboto text-sm"
            >
              Timeline
            </label>
            <input
                onChange={(e) => setName(e.target.value)}

              type="date"
              placeholder="Enter your full name"
              name="date"
              id="date"
              className="border border-gray-200 px-8 py-4"
            />
          </div> */}
          <div className="flex flex-col w-full space-y-2">
            <label
              htmlFor="email"
              className="font-medium tracking-widest uppercase font-roboto text-sm"
            >
              MORE INFORMATION
            </label>
            <textarea
            value={formik.values.information}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="textarea"
              placeholder="enter your messsage here"
              name="information"
              id="information"
              className="border border-gray-200 px-8 py-4 resize-none "
            />

            {
              formik.touched.information && formik.errors.information
              ?
              <span className="text-[red] text-sm md:text-md ml-3">
                {formik.errors.information}
              </span>
              :
              <></>
            }
          </div>

          <div className="relative w-full ">
            <Toaster
              containerStyle={{
                position: 'absolute',
              }}
              toastOptions={{
                duration: 4000,
                className: 'ml-[200px] w-full'
              }}
            />
          </div>

          <button className={`
            inline-flex items-center justify-center h-14 px-8 py-4 bg-[#459ED7]
            ${loading ? 'cursor-wait opacity-70' : 'cursor-pointer'}`
          }>
            <p className="text-base font-bold text-white uppercase">{loading ? 'Sending...' : 'Send request'}</p>
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Contacts;
