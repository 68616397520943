import React from 'react'
import PartnersNetwork from './PartnersNetwork'
import SpecializedSolution from './SpecializedSolution'
import Testimonial from './Testimonial'
import ServiceCoverage from './ServiceCoverage'
import HeroSection from './HeroSection'
import GetStarted from './GetStarted'

export const  XPadding = 'px-10 sm:px-[70px] lg:px-[60px] xl:px-[150px] 2xl:px-[150px] 3xl:px[500px]';

function Aws() {
  return (
    <div className='font-cabinet'>
      <HeroSection />
      <SpecializedSolution />
      <PartnersNetwork />
      <Testimonial />
      <GetStarted />
      {/* <ServiceCoverage /> */}
    </div>
  )
}

export default Aws