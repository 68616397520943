import { XPadding } from "./Rds";
import Wonder from "../../../assets/images/aws/wonder.png";
import { useState } from "react";
import "./rds.css";

const questions = [
  {
    title: 'What is database migration?',
    answer: `Database migration refers to the process of moving a database from one platform or environment to another.
      This can include moving a database from an on-premise environment to the cloud, or between different cloud environments.`,
  },
  {
    title: 'Why migrate databases to AWS?',
    answer: `There are several reasons why organizations choose to migrate their databases to AWS, including increased scalability, improved performance, reduced costs, and greater security.`,
  },
  {
    title: 'What are the different types of databases available on AWS?',
    answer: `AWS offers several types of databases, including relational databases (Amazon RDS), non-relational databases (Amazon DynamoDB),
      in-memory databases (Amazon ElastiCache), and managed graph databases (Amazon Neptune).`,
  },
  {
    title: 'How much does it cost to use AWS databases?',
    answer: `The cost of using AWS databases depends on several factors, including the type of database you choose,
      the size of the instances, and the amount of data and traffic you need to store and serve.
      AWS offers a pay-as-you-go pricing model, so you only pay for what you use.`,
  },
  {
    title: 'How do I migrate my database to AWS?',
    answer: `There are several methods for migrating databases to AWS, including manual database replication,
      AWS Database Migration Service (DMS), and AWS CloudFormation. Regardless of the method you choose,
      it's important to plan your migration carefully and work with a trusted AWS partner to ensure a smooth and successful migration.`,
  },
]

const FAQ = () => {
  const defaultState = [false, false, false, false, false, false];
  const [activeQuestions, setActiveQuestions] = useState(defaultState);

  return (
    <section className={`section ${XPadding} w-full my-[100px] flex flex-col md:flex-row items-center justify-center md:justify-start gap-10 xl:gap-20 font-cabinet`}>
      <h2 className="md:hidden font-extrabold text-[30px] text-center leading-[35px]">
        Frequently Asked Questions
      </h2>
      <img src={Wonder} alt='question?' className="w-[300px] lg:w-[400px] 2xl:w-[500px] md:self-start xl:self-center" />
      <div className="flex flex-col items-start">
        <h2 className="hidden md:block font-extrabold text-[30px] xl:text-[40px] text-center leading-[35px] mb-10">
          Frequently Asked Questions
        </h2>
        <div className="flex flex-col items-start gap-3">
          {
            questions.map((question, index) => {
              return (
                <div key={index} className="cursor-pointer
                  "
                  onClick={() => {
                    const oldValue = activeQuestions[index];
                    const newState = [...defaultState];
                    newState[index] = !oldValue;
                    setActiveQuestions(newState);
                  }
                }>
                  <div className="bg-[#F7F7F7] p-5 md:px-10 rounded-xl">
                    <span className="font-bold text-lg">{activeQuestions[index] ? '- ' : '+ '}</span>{question.title}
                    <div className={`py-10 px-5 md:px-10 mt-5 bg-white
                      text-sm border-[0.5px]border-[whitesmoke] shadow-md
                      ${activeQuestions[index] ? 'block' : 'hidden'}`
                    }>
                      {question.answer}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default FAQ;
