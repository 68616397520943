import { useLocation } from "react-router-dom";

const GetEquity = () => {
  const location = useLocation();
  const path = location.pathname;
  if (path === "/getequity") {
    return window.location.replace('https://forms.gle/ijMiW2n471XRMVZGA');
  }
};

export default GetEquity;
