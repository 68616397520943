import React from "react";
import Layout from "../components/shared/Layout";
import chp from "../assets/images/chp.png"
import chamberr from "../assets/images/chamberr.png"
import blockops from "../assets/images/blockops.png"
import Button from "../components/shared/Button";


const Products = () => {
  return (
    
    <Layout>
      <div className="flex flex-col space-y-40">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-y-[32px]">
          <div className="w-full lg:w-[40%] space-y-6">
            <p className="text-5xl font-extrabold leading-[105%] text-gray-900">
              Cloud Help Provider(CHP)
            </p>
            <p className="text-lg font-medium leading-loose text-gray-500">
              CHP is a cloud assessment tool that helps users analyze their IT
              requirements and match them with the right cloud platform. With CHP,
              users can smoothly adopt and migrate to the Cloud, ensuring that their
              IT capabilities meet their current and future needs for a better user experience.
              <br />
            </p>
          </div>
          <div className="w-full lg:w-[40%] min-h-[400px]">
            <img src={chp} alt="" />
          </div>
        </div>
        <div className="flex flex-col-reverse gap-y-[32px] lg:flex-row justify-between items-center">
          
          <div className="w-full lg:w-[40%] min-h-[400px]">
            <img src={chamberr} alt="" />
          </div>
          <div className="lg:w-[50%] space-y-6">
            <p className="text-5xl font-extrabold leading-[105%] text-gray-900">
            Chamberr
            </p>
            <p className="text-lg font-medium leading-loose  text-gray-500">
              Chamberr is a modern infrastructure solution designed for fast-growing
              companies that need to scale beyond the early stages. With Chamberr,
              you can deploy your code to various cloud platforms (such as AWS, GCP, Azure, and DigitalOcean)
              with a single command, without writing any code or managing servers. Chamberr is designed to be
              scalable, fault tolerant, and high performing, automatically handling load spikes as you grow.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-[40%] space-y-6">
            <p className="text-5xl font-extrabold leading-[105%] text-gray-900">
              BlockOps
            </p>
            <p className="text-lg font-medium leading-loose text-gray-500">
              BlockOps is a tool that helps fast-growing companies build their
              blockchain infrastructure in a scalable and cost-effective way.
              With BlockOps, anyone can launch and manage blockchain nodes in seconds,
              making it easier for startups to get started with blockchain technology
              and improve innovation, efficiency, and trust.
            </p>
            <Button>
            <a href="https://www.blockops.network/" target="_blank" rel="noreferrer">View product</a>
          </Button>
          </div>
          <div className=" w-full lg:w-[40%] min-h-[400px]">
            <img src={blockops} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
