import AWS from "../../../assets/images/aws/AWS-illustration.png";
import { XPadding } from "./Rds";
import "./rds.css";

const HeroSection = () => {
  return (
    <section className={`section ${XPadding} w-full font-cabinet flex flex-col md:flex-row-reverse
      gap-5 md:gap-2 items-center justify-between mt-10`
    }>
      <img src={AWS} alt='background' className="w-[500px] md:w-[250px] lg:w-[400px] xl:w-[450px] 2xl:w-[550px] self-start" />
      <div className="flex flex-col md:gap-1">
        <h1 className="text-[16px] md:text-[25px] xl:text-[30px] 2xl:text-[40px] leading-[20px] md:leading-[30px] xl:leading-[35px] 2xl:leading-[45px] font-extrabold">
          Empowering your business with managed, scalable databases on AWS
        </h1>
        <p className="text-md">
          Work with Nautilus Technologies, an AWS Partner, to modernize your database with fully
          managed database service to simplify provisioning, security, monitoring, and accelerate scalability.
        </p>
        <a href="#contact-form" className="rounded-[6px] md:rounded-[10px]  bg-[#277FFD] px-3 py-3 md:px-3 md:py-5
          text-white text-sm font-bold md:text-lg max-w-[150px] md:max-w-[200px] mt-3 text-center"
        >
          Plan Your Project
        </a>
      </div>
    </section>
  )
}

export default HeroSection;
