import { XPadding } from "../Aws";
import RobustCloud from "../../../assets/images/aws/RobustCloud.png";
import Expertise from "../../../assets/images/aws/expertise.png";
import "./rds.css";

const MainContent = () => {
  return (
    <section className="font-cabinet w-full">
      <div className={`section ${XPadding} bg-[#277FFD] mt-20 flex flex-col items-center justify-center gap-2 text-white
        py-[120px] md:py-[150px] text-center`
      }>
        <h2 className="font-bold text-[18px] md:text-[35px] xl:text-[45px]">
          Database Modernization
        </h2>
        <p className="text-[13px] md:text-[18px] max-w-[900px]">
          For businesses looking to move their on-premise DBs to AWS, Nautilus technologies delivers a holistic migration experience
          that leverages our extensive background with on-premise databases, migration tools, and AWS tools and technology.
          As an AWS consulting partner, we build on our extensive migration experience to AWS to deliver seamless migrations.
        </p>
      </div>
      <div className={`section ${XPadding} w-full bg-[#ECF2FE] flex flex-col items-center justify-center py-[120px] md:py-[150px]`}
      >
        <h2 className="font-extrabold text-[20px] md:text-[35px] xl:text-[45px]
          max-w-[800px] text-center md:leading-[40px] xl:leading-[50px]"
        >
          Why choose Nautilus as your preferred Cloud Database Partner?
        </h2>
        <div className="w-full flex flex-col md:flex-row items-center justify-between mt-[150px] gap-10">
          <img src={RobustCloud} alt='background' className="w-[500px] md:w-[300px] lg:w-[450px] self-start" />
          <div className="flex flex-col gap-3 md:gap-5 xl:ml-[120px] max-w-[800px]">
            <h2 className="text-[25px] md:text-[30px] xl:text-[35px] 2xl:text-[45px] font-extrabold md:leading-[35px] 2xl:leading-[45px]">
              Scalability
            </h2>
            <p className="text-sm md:text-lg">
              We understand the importance of scalability and can help you to design cloud databases that can handle your growing data needs. 
            </p>
          </div>
        </div>
      </div>
      <div className={`
        section ${'pl-10 sm:pl-[70px] lg:pl-[60px] xl:pl-[150px] 2xl:pl-[150px] pr-10'}
        w-full flex flex-col md:flex-row items-center justify-between mt-[60px] gap-10`}
      >
        <div className="flex flex-col gap-3 md:gap-5 max-w-[600px]">
          <h2 className="text-[25px] md:text-[30px] xl:text-[35px] 2xl:text-[45px] font-extrabold md:leading-[35px] 2xl:leading-[45px]">
            Expertise in Key AWS Database Services
          </h2>
          <p className="text-sm md:text-lg">
            Our professionals have been working with Amazon database service since the beginning and are keen on best practices that will save you time and money.
            Our extensive team of experts help businesses with database monitoring, security and performance, automating time-consuming administrative tasks and
            enabling companies to focus on their core business.
          </p>
        </div>
        <img src={Expertise} alt='background' className="w-[700px] md:w-[350px] lg:w-[550px] xl:w-[650px] self-end" />
      </div>
    </section>
  )
}

export default MainContent;
