import React from 'react'
import CaseStudies from '../components/CaseStudies'
import Contacts from '../components/Contacts'
import FAQ from '../components/FAQ'
import Hero from '../components/Hero'
import { NewsLetter } from '../components/NewsLetter'
import Partners from '../components/Partners'
import SpecializationNew from '../components/SpecializationNew'
import HeroSection from '../components/microsite/HeroSection'
import { useEffect,useState } from 'react'
import { ContactScrollContext } from '../contexts/GlobalContext'
import { useContext } from 'react'
import { useLocation } from "react-router-dom";



export const Home = () => {
  
  let {state} = useLocation();
  const { scroll } = state || false

  const [contactScroll, setContactScroll] = useContext(ContactScrollContext);
  const [scrollUrl,setScrollUrl] = useState(scroll)

  useEffect(() => {
    if (scrollUrl) {
      setContactScroll(true)
   

    }
  },[scrollUrl])
  
  

  useEffect(() => {
    if (contactScroll) {
      const contactPosition = document.body.scrollHeight;
      window.scrollTo(0, (0.815 * contactPosition));
      setContactScroll(false);
    
      
    }
  }, [contactScroll])


  return (
    <>
    <Hero/>
    <div className="h-16"></div>
    <SpecializationNew/>
    <div className="h-16"></div>
    {/* <Specialization/> */}
    <CaseStudies/>
    <div className="h-16"></div>
    <FAQ/>
    <div className="h-16"></div>
    <NewsLetter/>
    <div className="h-16"></div>
    <Partners/>
    <div className="h-16"></div>
    <HeroSection/>
    <div className="h-16"></div>
    <Contacts/>
    <div className="h-16"></div>
    
    </>
  )
}
