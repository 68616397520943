import { useLocation} from "react-router-dom";

const Onboard = () => {
  const location = useLocation();
  const path = location.pathname;
  if (path === "/onboard") {
    return window.location.replace('https://bit.ly/nautilusonboarding');;
  }
};

export default Onboard;
