import { XPadding } from "../Aws";
import { useFormik } from "formik";
import * as Yup from "yup";
import ContactVideo from "../../../assets/videos/aws/contact-us.mp4";
import Lottie from "lottie-react";
import ContactAnim from "../../../assets/animations/contact-us.json";
import "./rds.css";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { alertSuccess, alertError } from "../../../utils/index";
import { Toaster } from 'react-hot-toast';

const NextStep = () => {
  const  [loading, setLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: "",
      lastname: "",
      companyName: "",
      email: "",
      information: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Firstname is required."),
      lastname: Yup.string(),
      companyName: Yup.string(),
      email: Yup.string().required("Please provide a valid email address."),
      information: Yup.string().required("Please enter a messeage.")
        .min(5, 'Message must be a minimum of 10 characters.')
        .max(100, "Message must be at most 100 characters."),
    }),
    onSubmit: (values) => {
      setLoading(true)
      values.name = `${values.firstname} ${values.lastname}`
      delete values.firstname
      delete values.lastname
      emailjs
      .send(
        "service_508619j",
        "template_najcyu5",
        values,
        "wwX744bFmCPnEBhVe"
      )
      .then(
        (result) => {
          console.log(result.text);
          alertSuccess('Message sent! We would respond to you shortly.');
          setLoading(false);
          formik.resetForm();
        },
        (error) => {
          alertError('Error. Please check your email and retry');
          console.log(error.text);
        }
      );
    }
  });

  return (
    <section id="contact-form" className={`section ${XPadding} w-full bg-[#DCE8FF] py-20 mb-[-40px] font-cabinet`}>
      <h2 className="font-extrabold text-[21px] md:text-[40px] text-center md:leading-[40px]">
        Ready to Take the Next Step?
      </h2>
      <p className="text-[19px] text-center md:text-[30px] mt-2">
        Contact Our Cloud Experts Today
      </p>
      <form onSubmit={formik.handleSubmit} className="mt-10 flex flex-col gap-3">
        <div className="flex flex-col md:grid grid-cols-2 gap-3 xl:gap-x-10">
          {/* Firstname input */}
          <div className="flex flex-col gap-1">
            <label htmlFor="firstname" className="font-bold ml-3 text-sm md:text-lg">First Name</label>
            <input
              id="firstname"
              name="firstname"
              value={formik.values.firstname}
              type="text"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full outline-none p-5 pl-6 md:pl-10 md:py-7 md:text-xl rounded-[15px] font-sans"
            />
            {
              formik.touched.firstname && formik.errors.firstname
              ?
              <span className="text-[red] text-sm md:text-lg ml-3">
                {formik.errors.firstname}
              </span>
              :
              <></>
            }
          </div>

          {/* Lastname input */}
          <div className="flex flex-col gap-1">
            <label htmlFor="lastname" className="font-bold ml-3 text-sm md:text-lg">Last Name</label>
            <input
              id="lastname"
              name="lastname"
              value={formik.values.lastname}
              type="text"
              placeholder="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full outline-none p-5 pl-6 md:pl-10 md:py-7 md:text-xl rounded-[15px] font-sans"
            />
            {
              formik.touched.lastname && formik.errors.lastname
              ?
              <span className="text-[red] text-sm md:text-lg ml-3">
                {formik.errors.lastname}
              </span>
              :
              <></>
            }
          </div>

          {/* Company name input */}
          <div className="flex flex-col gap-1">
            <label htmlFor="companyName" className="font-bold ml-3 text-sm md:text-lg">Company Name</label>
            <input
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              type="text"
              placeholder="Company Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full outline-none p-5 pl-6 md:pl-10 md:py-7 md:text-xl rounded-[15px] font-sans"
            />
            {
              formik.touched.companyName && formik.errors.companyName
              ?
              <span className="text-[red] text-sm md:text-lg ml-3">
                {formik.errors.companyName}
              </span>
              :
              <></>
            }
          </div>

          {/* Work email input */}
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="font-bold ml-3 text-sm md:text-lg">Work Email</label>
            <input
              id="email"
              name="email"
              value={formik.values.email}
              type="email"
              placeholder="Work Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full outline-none p-5 pl-6 md:pl-10 md:py-7 md:text-xl rounded-[15px] font-sans"
            />
            {
              formik.touched.email && formik.errors.email
              ?
              <span className="text-[red] text-sm md:text-lg ml-3">
                {formik.errors.email}
              </span>
              :
              <></>
            }
          </div>
        </div>

        {/* Message input */}
        <div className="flex flex-col gap-1">
          <label htmlFor="information" className="font-bold ml-3 text-sm md:text-lg">Message</label>
          <textarea
            id="information"
            name="information"
            value={formik.values.information}
            type="text"
            placeholder="Describe your needs"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full h-[200px] md:h-[350px] text-xl outline-none p-5 pl-6 rounded-[15px]"
          />
          {
            formik.touched.information && formik.errors.information
            ?
            <span className="text-[red] text-sm md:text-lg ml-3">
              {formik.errors.information}
            </span>
            :
            <></>
          }
        </div>

        <Toaster
          containerStyle={{
            position: 'relative',
          }}
          toastOptions={{
            duration: 4000,
            className: 'max-w-[400px]'
          }}
        />

        {/* Submit button */}
        <button
          type="submit"
          className={`
            w-full bg-[#277FFD] py-5 md:py-9 rounded-[10px] mt-5 text-sm
            md:text-md text-white font-bold font-sans
            ${loading ? 'cursor-wait opacity-70' : 'cursor-pointer'}`
        }>
          {loading ? 'Just a moment...' : 'send us a message'}
        </button>
      </form>
      <div className="self-center pb-20 flex justify-center">
        <Lottie className="w-full md:w-[80%] h-full m-0 aspect-video object-cover self-center"
          animationData={ContactAnim}
        />
      </div>
    </section>
  )
}

export default NextStep;
