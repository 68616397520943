import React from "react";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-magic-slider-dots/dist/magic-dots.css";
import Prudential from "../../assets/images/aws/african-prudential-logo.png";
import Grocedy from "../../assets/images/aws/grocedy-logo.png";
import Composable from "../../assets/images/aws/composable-logo.png";
import FourStars from "../../assets/images/aws/4stars.png";
import { useState } from "react";
import { XPadding } from "./Aws";
import "./aws.css";


export const testimonials = [
  {
    id: 1,
    img: Prudential,
    name: "Africa Prudential",
    position: "CTO",
    testimonial: {
      title: 'Best SRE we have worked with.',
      stars: FourStars,
      text: `The guys at Nautilus are the best SRE I have worked with. Our Infrastructure is
        highly secure and application performance is exceptional. Also their documentation is impeccable.
        Nautilus does have great engineers.`,
    },
    active: false,
  },
  {
    id: 2,
    img: Grocedy,
    name:"Grocedy",
    position: "CEO",
    testimonial: {
      title: 'High level of professionalism with great attention to detail.',
      stars: FourStars,
      text: `Nautilus technologies have been a valued partner in helping us optimise, build and migrate
        our infrastructures on AWS. They combine professionalism and attention to detail with a constructive
        and welcoming attitude, and we continue to work with them as a trusted technology partner. Our IT
        infrastructure is now more scalable, cost-efficient, reliable and secure due to the AWS features that
        the team have implemented.`,
    },
    active: false,
  },
  {
    id: 3,
    img: Composable,
    name:"Composable",
    position: "Princial Engineer",
    testimonial: {
      title: 'Great for managing Blockchain nodes and networks.',
      stars: FourStars,
      text: `Nautilus team has helped us operational security and performance challenges while deploying and
        managing Blockchain nodes and networks. Nautilus automates the entire DevOps lifecycle for accelerating
        the Enterprise Blockchain implementation. The team communicates with the internal team here to ensure a
        smooth workflow.`,
    },
    active: true,
  },
];

function Testimonial() {
  /* const [miltonActive, setMiltonActive] = useState(true);
  const [chiomaActive, setChiomaActive] = useState(false);
  const [gabrielActive, setGabrielActive] = useState(false); */
  const [current, setCurrent] = useState(testimonials[0]);

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",

    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={6} dotWidth={30} />;
    },
  };

  return (
    <div className={`section flex flex-row items-center justify-center md:justify-between 2xl:justify-start 2xl:gap-[50px] gap-1 py-[64px] bg-[#F9FBFF] ${XPadding}`}>
      <div className="relative self-center bg-[#F9FBFF] h-auto bg-white flex flex-col items-center gap-3">
        {
          testimonials.map(query => {
            return (
              <div key={query.id} className="flex flex-col">
                <div onClick={() => setCurrent(testimonials[query.id - 1])} className={`
                  ${current.id === query.id ? 'shadow-[0px_18px_65px_rgba(11,13,32,0.07)] bg-white' : 'bg-[#F9FBFF]'}
                  w-[320px] sm:w-[350px] lg:w-[400px] xl:w-[500px] py-5 sm:py-7 xl:h-[150px]
                  bg-white rounded-[15px] flex flex-row items-center justify-between px-5 md:px-10 cursor-pointer
                  border border-[1px] border-[whitesmoke] border-opacity-50`
                }>
                  {
                    query.name !== 'Grocedy'
                    ?
                    <div className="rounded-full flex items-center justify-center w-[70px] h-[70px] xl:w-[80px] xl:h-[80px] border-[0.5px] px-2">
                      <img src={query.img} alt="" width={60}/>
                    </div>
                    :
                    <img src={query.img} alt="" className="rounded-full flex items-center justify-center w-[70px] h-[70px] xl:w-[80px] xl:h-[80px] border-[0.5px]"/>
                  }
                  <div className="w-[70%] flex flex-col text-left">
                    <h2 className="text-xl font-bold">{query.name}</h2>
                    {/* <p className="text-sm text-[#5D5D5D]">{query.position}</p> */}
                  </div>
                </div>

                <div className={`
                  md:hidden
                  ${current.id === query.id ? 'visible' : 'hidden'}
                  bg-[#F9FBFF] w-[320px] sm:w-[350px] p-5 mt-1`
                }>
                  <h2 className="text-lg font-bold pb-3">{current.testimonial.title}</h2>
                  <img src={current.testimonial.stars} alt='stars' className="h-5 w-[120px] mb-2" />
                  <p className="text-sm">{current.testimonial.text}</p>
                </div>
              </div>
            )
          })
        }
      </div>
      {/* for larger scrrens */}
      <div className={` 
        w-[400px] xl:w-[550px] 2xl:w-[650px] h-[375px] mt-1
        hidden md:flex flex-col ml-10 self-start
        transition-all ease-in ease-out duration-200`
      }>
        <h2 className="text-lg xl:text-xl font-bold pb-3">{current.testimonial.title}</h2>
        <img src={current.testimonial.stars} alt='stars' className="h-5 w-[130px] mb-5" />
        <p className="text-sm xl:text-lg">{current.testimonial.text}</p>
      </div>
    </div>
  );
}

export default Testimonial;
